<template >
  <div >
    <div class="md:flex block justify-between text-center ">
      <h4 class="text-white my-4 lg:text-2xl md:text-xl text-base d-block">ایجاد تیکت پشتیبانی</h4>
      <div class="flex items-center gap-2">

        <b-button @click="handleDocLink()" class="text-sm md:text-base md:px-12 px-8 my-4  bg-h-secondary" variant="info">
          <span>مستندات </span>
        </b-button>
        <!-- <router-link to="/dashboard" class="text-sm md:text-base text-grey border border-dashed border-white border-opacity-25 rounded py-2 px-4">بازگشت به داشبورد</router-link> -->
      </div>
    </div>
    <div class="mt-8" v-if="!showForm">
      <h2>سوالات متداول</h2>
      <span>لطفا ابتدا سوالات زیر را بررسی کنید تا سریعتر به پاسخ برسید، درصورتی که پاسخ شما در این سوالات نبود اقدام به ثبت تیکت کنید.</span>

      <!-- FAQ Accordion -->
      <div class="mt-8">
        <vs-collapse accordion type="margin">
     <vs-collapse-item class="border border-white border-opacity-25">
       <div slot="header" >
        ظرفیت سرور ها کی خالی می شود
       </div>
       <div class="bg-h-primary p-6 rounded leading-loose text-lg -m-4">
          موجودی سرور ها بصورت لحظه ای درحال تغییر می باشد و دقیق مشخص نیست کی
          ظرفیت خالی ایجاد میشود،
          <br />
          لذا پیشنهاد میشود مرتب از طریق پنل ساخت سرور را بررسی
          کنید تا بالفاصله بعد از آزاد شدن اولین آیپی بتوانید اقدام به ساخت کنید.
       </div>
      </vs-collapse-item>
     <vs-collapse-item class="border border-white border-opacity-25">
       <div slot="header" >
        درخواست عودت وجه
       </div>
       <div class="bg-h-primary px-6 py-4 rounded leading-loose text-lg -m-4">
        درخواست عودت وجه طبق<a href="https://haio.ir/terms" class="text-h-secondary" target="_blank"> قوانین سایت</a> انجام می شود و اگر شرایط ذکر شده در<a href="https://haio.ir/terms" class="text-h-secondary" target="_blank"> قوانین سایت</a> شامل درخواست شما نباشد عودت وجه انجام نمیشود.
        <br />
        <strong> برای مثال:</strong>
        <ul>
          <li>         عودت وجه برای درخواست های زیر 300 هزار تومان انجام نمی شود.</li>
          <li>عودت وجه فقط درصورتی که هایو هیچ سرویسی برای ارائه نداشته باشد انجام میگیرد.</li>
          <li>عودت وجه فقط درصورتی که سرویس دریافتی شما از همان ابتدا ناقص ارائه شده باشد انجام می شود، لذا اگر سرویسی دریافت کنید و و مشکلی در ارتباط با سرویس و کار با آن وجود نداشته باشد شرایط عودت وجه برای شما وجود نخواهد داشت.</li>
          <li>راه اندازی هرگونه پروکسی و محدودیت های زیرساخت و یا تنظیمات اشتباه شما شامل تعهدات هایو نمی باشند.</li>
        </ul>
          <br />
          با توجه به موارد بالا و موارد دیگر در صفحه<a href="https://haio.ir/terms" class="text-h-secondary" target="_blank"> قوانین سایت</a> درصورتیکه عودت وجه شامل شرایط شما می شود مدارک زیر را جهت عودت وجه ارسال نمایید:
          <ul>
            <li>تصویر کارت ملی</li>
            <li>شماره کارت و شبا واریز کننده وجه</li>
            <li>اطلاعات پنل کاربری شما باید مطابق با اطلاعات واریز کننده و کارت ملی باشد.</li>
          </ul>
       </div>
      </vs-collapse-item>
      <vs-collapse-item class="border border-white border-opacity-25">
       <div slot="header" >
        عدم افزایش اعتبار حساب یا واریزی اشتباه
      </div>
       <div class="bg-h-primary p-6 rounded leading-loose text-lg -m-4">
          جهت بررسی وضعیت واریزی شما لطفا ابتدا چند ساعت صبر کنید درصورتی که مبلغ به حساب شما برنگشت مدارک زیر را ارسال فرمایید:
          <br />
          <ul>
            <li>مبلغ واریزی</li>
            <li>شماره کارت واریز کننده</li>
            <li>ساعت و تاریخ دقیق واریز</li>
          </ul>
       </div>
      </vs-collapse-item>
      <vs-collapse-item class="border border-white border-opacity-25">
       <div slot="header" >
        نمی توانم به سرور وصل بشوم
       </div>
       <div class="bg-h-primary p-6 rounded leading-loose text-lg -m-4">
        پورت پیشفرض اتصال به سرور 2280 است لذا لطفا ابتدا اطمینان حاصل کنید که از پورت 2280 برای اتصال به سرور استفاده میکنید،
        <br /><br />
        اگر
مشکل با پورت 2280 همچنان باقی بود و نمیتوانستید وصل بشید پینگ سرور رو بررسی
کنید ببنید پینگ دارید یا خیر و بعد وضعیت رو به ما خبر بدید؛
<br /><br />
درصورتیکه قبلا یکبار به سرور وصل شدید و تنظیم خاصی انجام داده اید عدم اتصال
احتمال دارد بخاطر تنظیمات شما و یا فایروال باشد،
<br />
لذا درصورتی که کار زیادی روی سرور
انجام ندادید از صفحه مشخصات سرور نصب مجدد سیستم عامل رو بزنید تا سیستم عامل
مجدد خام شود تا بتوانید به سرور وصل بشوید.
<br />
لطفا اینبار بعد از اتصال ابتدا پورت 2280 رو به فایروال اضافه و یا در تنظیماتی که قصد
انجام آن را دارید استثنا کنید.
<br /><br />
درصورتیکه سرور را نیاز دارید و نمیخواهید نصب مجدد سیستم عامل انجام شود از طریق
 تیکت اطلاع دهید تا راهکارهای دیگر برای درخواست شما بررسی شود.
 <br /><br />
در نهایت اگر سرور را به تازگی تهیه کردید و تا به حال به سرور وصل نشدید و هیچ تنظیمی
روی سرور انجام نداید احتمالا آیپی سرور توسط کاربر قبلی فیلتر شده است، لذا پیشنهاد می شود سرور فعلی را خاموش کنید و اقدام به ساخت سرور جدید کنید تا آیپی جدید به شما اختصاص یابد، سپس به پشتیبانی اطلاع دهید تا آیپی را از پنل حذف کرده تا توسط کاربر دیگر مجدد دریافت نشود.
       </div>
       </vs-collapse-item>
       <vs-collapse-item class="border border-white border-opacity-25">
       <div slot="header" >
        راه اندازی پروکسی در سرور
       </div>
       <div class="bg-h-primary p-6 rounded leading-loose text-lg -m-4">هایو صرفا ارائه دهنده سرور ابری است، تنظیمات و برنامه هایی که شما در سرور قصد راه
اندازی دارید کاملا بر عهده خودتان است و محدودیتی وجود ندارد، اما دقت داشته باشید
طبق قوانین کشور هیچگونه پشتیبانی فنی برای راه اندازی انواع پروکسی و تعهدی بابت
محدودیت های زیرساخت ارائه نمی شود.
       </div>
       </vs-collapse-item>
       <vs-collapse-item class="border border-white border-opacity-25">
       <div slot="header" >
        درخواست تغییر آیپی
       </div>
       <div class="bg-h-primary p-6 rounded leading-loose text-lg -m-4">بطور پیشفرض امکان تغییر آیپی روی سرور فعال وجود ندارد، برای دریافت آیپی جدید
میتوانید سرور جدیدی ایجاد کنید و سپس سرور فعلی را حذف کنید.
مد نظر داشته باشید که با اینکار تمام اطلاعات شما حذف خواهد شد.
       </div>
       </vs-collapse-item>
       <vs-collapse-item class="border border-white border-opacity-25">
       <div slot="header" >
        هزینه سرور خاموش
             </div>
       <div class="bg-h-primary p-6 rounded leading-loose text-lg -m-4">درحالت خاموش فقط هزینه هارد و آیپی سرور جهت رزرو نگه داشتن آنها برای شما کسر
می شود.
       </div>
       </vs-collapse-item>
       <vs-collapse-item class="border border-white border-opacity-25">
       <div slot="header" >
        پرداخت ساعتی هست یا ماهیانه       </div>
       <div class="bg-h-primary p-6 rounded leading-loose text-lg -m-4">هزینه سرورهای ابری هایو تماما بصورت ساعتی از شارژ شما کسر می شود و هزینه
ماهیانه صرفا جهت اطلاع شما آورده شده است که درصورت روشن ماندن سرور به مدت
یک ماه به آن میزان از شارژ شما کسر خواهد شد.

       </div>
       </vs-collapse-item>

      </vs-collapse>
      </div>
      <div class="flex justify-end gap-3">
        <vs-button @click="showForm = !showForm" class="rounded text-sm md:text-base py-1 md:py-2"
        color="#1791DA" gradient-color-secondary="#0092e1" type="gradient">
        قصد ارسال تیکت دارم</vs-button>
        <!-- <router-link to="/dashboard" class="text-sm md:text-base text-grey border border-dashed border-white border-opacity-25 rounded py-2 px-4">بازگشت به داشبورد</router-link> -->
      </div>
    </div>

    <form v-if="showForm" class="md:p-4 " @submit.prevent="getFormValues">
      <div class="row my-4 px-2">
        <b-form-input
          v-model="formData.subject"
          class="my-2 col-12 bg-h-primary py-3 h-12 border-grey-200 border-opacity-25 text-white"
          placeholder="عنوان "
        ></b-form-input>
        <!-- Start of AutoAnswer condition to check if AutoAnswer.state is true or not -->
        <div v-if="AutoAnswer.state" class="w-full bg-h-primary p-2 md:p-5 rounded bg-opacity-75 border border-h-primary mt-4 mb-4">
          <span class="text-xl text-white block mb-4">پاسخ احتمالی شما:</span>
          <!-- Loop through AutoAnswer.links using v-for -->
          <div :key="i" v-for="(answer, i) in AutoAnswer.links" class="mb-5">
            <span class="bg-h-success w-4 h-4 inline-block -mb-1 p-1 rounded-full"> </span>
            <!-- Display a link if the answer is not '#' -->
            <a  v-if="answer != '#'" :href="answer" target="_blank" class="text-white md:bg-h-primary-light p-1 px-4 rounded-lg leading-loose md:text-base text-sm" >{{ AutoAnswer.titles[i] }}</a>
            <!-- Display a span if the answer is '#' -->
           <span  v-if="answer == '#'" class="text-white md:bg-h-primary-light p-1 px-4 rounded-lg leading-loose md:text-base text-sm" >{{ AutoAnswer.titles[i] }}</span>
          </div>
        </div>
        <!-- End of the AutoAnswer condition -->
        <b-form-select
          class="md:mx-2 my-2 col-md col-12 bg-h-primary text-white h-12 border-grey-200 border-opacity-25"
          v-model="formData.label_id"
          :options="serviceOptions"
        ></b-form-select>
        <b-form-select
          class="md:mx-2 my-2 col-md col-12  bg-h-primary text-white h-12 border-grey-200 border-opacity-25"
          v-model="formData.category_id"
          :options="departementOptions"
        ></b-form-select>
      </div>
      <vs-alert v-if="formData.category_id != 1" color="warning" class="p-2 rounded block mb-4">
        نکته: زمان پاسخ دهی به تیکت های مرتبط به بخش فروش و مالی در ساعات اداری به جزء روزهای پنجشنبه و جمعه می باشد.</vs-alert>
      <p class="text-white md:text-base text-sm">
        پیشنهاد میشود قبل از ایجاد, سوال خود را در لیست سوالات متداول و یا در
        <span class="font-bold" @click="handleDocLink()"  role="button" >مستندات هایو</span> جستجو کنید.
      </p>

      <b-textarea
        rows="5"
        style="direction: rtl"
        v-model="formData.body"
        class="bg-h-primary text-white border-grey-200 border-opacity-25 "
        label=" پیام خود را وارد کنید"
      />
      <div class="flex items-center">
      <!-- <vs-icon icon="close"></vs-icon> -->
      <b-form-file
        v-model="formData.attachment"
        class="mt-3 bg-h-primary-light"
        plain
      ></b-form-file>
      </div>

      <div class="flex justify-end">
        <b-button @click="$emit('exit')" class="my-4 px-12 mx-2"  variant="light">انصراف </b-button>
        <b-button type="submit" variant="md:px-12 px-12 py-3 my-4 bg-h-secondary text-white md:text-base text-sm">

          <span> ارسال تیکت</span>
        </b-button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  data () {
    return {
      AutoAnswer: {
        state: false,
        link: '',
        title: ''
      },
      formData: {
        category_id: 1,
        label_id: null
      },
      serviceOptions: [
        { value: null, text: 'سرویس مد نظر' },
        { text: 'سرور ابری ', value: '1' },
        { text: 'خدمات اختصاصی', value: '6' }
      ],
      departementOptions: [
        { text: ' پشتیبانی فنی', value: '1' },
        { text: ' فروش و بازاریابی', value: '2' },
        { text: ' مالی و اداری', value: '3' }

      ],
      showForm: ''
    }
  },
  watch: {
    'formData.body': {
      handler: 'preMadeAnswers'
    }
  },
  methods: {
    handleDocLink () {
      window.open('https://www.haio.ir/category/documentation/')
    },
    getFormValues () {
      const f = new FormData()

      for (const key in this.formData) {
        f.append(key, this.formData[key])
      }
      this.$emit('create-ticket', f)
    },
    preMadeAnswers () {
      // This object stores key-value pairs to determine if the state is true and the necessary links and titles for each key
      const autoAnswerMap = {
        'افزایش اعتبار': {
          state: true,
          links: [
            'https://www.haio.ir/no-credit-after-payment/',
            'https://www.haio.ir/credit-and-payment/',
            '#'
          ],
          titles: [
            'شارژ نشدن حساب کاربری پس از پرداخت',
            'نحوه افزایش اعتبار، نکات مهم قبل از افزایش اعتبار و حداقل مبلغ',
            'عدم انتقال به صفحه درگاه بانک به دلیل اختلال موقت در درگاه می باشد. کمی بعد مجدد تلاش کنید'
          ]
        },
        'فیلتر': {
          state: true,
          links: ['https://www.haio.ir/blocked-ip/'],
          titles: ['فیلتر بودن آیپی / عدم اتصال به سرور']
        },
        'درحال ساخت': {
          state: true,
          links: ['https://www.haio.ir/server-build-failed/'],
          titles: ['ساخته نشدن سرور یا درحال ساخت ماندن سرور']
        },
        'ساخته نشدن': {
          state: true,
          links: ['https://www.haio.ir/server-build-failed/'],
          titles: ['ساخته نشدن سرور یا درحال ساخت ماندن سرور']
        },
        'ساخت سرور': {
          state: true,
          links: ['https://www.haio.ir/create-new-server/', 'https://www.haio.ir/server-build-failed/'],
          titles: ['آموزش ساخت سرور و جزئیات مربوط به آن', 'ساخته نشدن سرور یا درحال ساخت ماندن سرور']
        }
      }
      // This variable matches the pattern in the key of the object with the message body entered by the user
      const matches = Object.keys(autoAnswerMap).filter(pattern => this.formData.body.includes(pattern))

      // If there are matching patterns, the links and titles for them are merged and returned
      if (matches.length > 0) {
        this.AutoAnswer = matches.reduce((acc, match) => {
          const answer = autoAnswerMap[match]
          acc.links = acc.links.concat(answer.links)
          acc.titles = acc.titles.concat(answer.titles)
          return acc
        }, {
          state: true,
          links: [],
          titles: []
        })
      } else {
        // If there are no matching patterns, returns an object with state false and empty links and titles arrays
        this.AutoAnswer = {
          state: false,
          links: [],
          titles: []
        }
      }
    }
  }
}
</script>
