<template>
  <div>
    <b-card data-cy="table" class=" overflow-x-scroll bg-h-primary-light ">
      <b-table
        class="text-start text-xs md:text-base align-middle border-radius rounded-lg table  overflow-scroll"
        selectable
        select-mode="single"
        bordered
        @row-selected="onRowSelected"
        head-variant=""
        :items="ticketList"
        :fields="fields"
        striped
      >
       <template #cell(category_id)="id">
          <div class="w-full">
            <p class="truncate">{{ departementText(id.value) }}</p>
          </div>
      </template>
      <template #cell(subject)="sub">
          <div class="w-full">
            <p class="truncate">{{ sub.value }} </p>
          </div>
      </template>
        <template #cell(status_id)="row">
          <span :style="{ color: statusColor(row.value) }">{{
            statusText(row.value)
          }}</span>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
export default {
  props : {
    ticketList : {
      type : Array,
      default : () => []
    }
  },
  data () {
    return {
      fields  : [
        {key: 'id', label: 'شماره'},
        {key: 'subject', label: 'عنوان تیکت'},
        {key: 'category_id', label: 'دپارتمان'},
        {key: 'agent_fullname', label: 'پاسخ دهنده'},
        {key: 'status_id', label: 'وضعیت' }
      ]

    }

  },
  computed : {
    windowWidth () {
      return this.$store.state.windowWidth
    }
  },
  methods : {
    departementText (id) {
      const arr = ['پشتیبانی فنی', ' فروش و بازاریابی', 'مالی و اداری']
      return arr[+id - 1]
    },
    statusColor (id) {
      const arr = ['#0070C0', '#FFC000', ' #92D050', '#00B050', '#FFC000', '#00B0F0', '#fff']
      return arr[+id - 1]
    },
    statusText (id) {
      const arr = ['جدید', 'در انتظار پاسخ', 'پاسخ مشتری', 'پاسخ کارشناس', 'در حال بررسی', 'در دست اقدام', 'بسته شده']
      return arr[+id - 1]
    },
    onRowSelected (item) {
      this.selectedTicket = item[0]
      this.$emit('onTicketSelect', item[0])
    }
  }
}
</script>

<style>
[dir] .table-striped tbody tr:nth-of-type(odd) {
    background-color: #20223c;
}
[dir] .table-striped tbody tr {
    background-color: #262c49;
    color:#fff  
}
thead {
    background-color: #dbdbdb;
    color: #090c2b;
}
</style>