<template>
  <div>

  <vx-card class="md:px-12 px-0 bg-h-primary-light">
      <!-- header -->
      <div v-if="!showCreateForm">
        <div class="md:flex text-center justify-between md:px-6 px-0">
        <h4 class="text-white my-4 dana-font md:text-2xl text-base ">تیکت پشتیبانی</h4>
        <div class="items-center flex">
          <b-button
          @click="showCreateForm = true"
          variant="px-12 my-4 text-sm md:text-base text-white bg-h-secondary"
          >
          <b-icon scale="1.2" icon="plus" />
          <span>  تیکت جدید</span>
        </b-button>
        <!-- <router-link to="/dashboard" class="ml-2 text-sm md:text-base text-grey border border-dashed border-white border-opacity-25 rounded py-2 px-4">بازگشت به داشبورد</router-link> -->
      </div>
    </div>
      </div>
      <!-- ticket list -->
      <div v-if="!showCreateForm">
        <TicketList  @onTicketSelect="selectTicket" :ticketList="ticketList" />
      </div>
      <!-- create ticket -->
      <div v-else>
        <CreateTicket @create-ticket="createTiket($event);showCreateForm = false"  @exit="showCreateForm = false" />
      </div>
    </vx-card>
  </div>
</template>

<script>
import CreateTicket from './components/CreateTicket.vue'
import TicketList from './components/TicketList.vue'
export default {
  components: {
    CreateTicket,
    TicketList
  },
  data () {
    return {
      ticketList : [],
      showCreateForm : false,
      test: ''

    }
  },
  methods : {
    getTiket () {
      this.$http.get('/ticket').then(res => {
        this.ticketList = res.data.params.data
      }).catch(err => {
        this.$vs.notify({
          color:'danger',
          title: err.response.data.message
        })
      })
    },
    createTiket (form_data) {
      this.$vs.loading()
      this.$http.post('/ticket', form_data, {headers: {'Content-Type': 'multipart/form-data' }}).then(res => {
        this.$vs.notify({
          color:'success',
          title:'تیکت ',
          text:res.data.message
        })
        this.getTiket()
        this.$vs.loading.close()
      }).catch(err => {
        this.$vs.loading.close()
        this.$vs.notify({
          color:'danger',
          title:'تیکت ',
          text:err.response.data.message
        })
        this.getTiket()
      })
    },
    selectTicket ($e) {
      // console.log($e)
      this.$router.push(`/apps/tickets/${$e.id}`)
    }

  },
  created () {
    this.getTiket()
  }
}
</script>
